import React, { useState } from "react";

import { Link } from "react-router-dom";

import data from "../../assets/fake-data/data-blog";

const data1 = [
  { id: 0, img: "https://placehold.co/20", title: "Token Info" },
  { id: 1, img: "https://placehold.co/40", title: "Distribution" },
  { id: 2, img: "https://placehold.co/40", title: "Anti Bot" },
  { id: 3, img: "https://placehold.co/40", title: "Tax" },
  { id: 4, img: "https://placehold.co/40", title: "Initial Liquidity" },
];

function Sidebar(props) {
  const { setStep } = props;
  return (
    <div className="sidebar">
      <div className="widget-sidebar recent">
        <h5 className="heading">Create Token</h5>
        <ul>
          {data1.map((idx) => (
            <li key={idx.id} onClick={() => setStep(idx.id)}>
              <div className="image">
                <a >
                  <img src={idx.img} alt="cyfonii" />
                </a>
              </div>

              <div className="content mt-2">
                <a to="/blog-details" className="title">
                  {idx.title}
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
