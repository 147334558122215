import React, { useEffect } from "react";
import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";
import Sidebar from "../components/sidebar/Sidebar";
import { Link } from "react-router-dom";

import img1 from "../assets/images/layouts/blog-detail.png";
import avt1 from "../assets/images/layouts/avt-01.png";
import avt2 from "../assets/images/layouts/avt-02.png";
import avt3 from "../assets/images/layouts/avt-03.png";
import avt4 from "../assets/images/layouts/avt-04.png";
import InfoInput from "../mycomponents/InfoInput";
import Input from "../mycomponents/Input";
import { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import axios from "axios";

function BlogDetails(props) {
  const [focusedTextArea, setFocusedTextArea] = useState(false);

  //Distribution akordiyon
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // Anti-bot protection akordiyon

  const [isLimitPerWalletOpen, setIsLimitPerWalletOpen] = useState(false);
  const [isLimitPerTransactionOpen, setIsLimitPerTransactionOpen] =
    useState(false);

  const toggleLimitPerWalletAccordion = () => {
    setIsLimitPerWalletOpen(!isLimitPerWalletOpen);
  };

  const toggleLimitPerTransactionAccordion = () => {
    setIsLimitPerTransactionOpen(!isLimitPerTransactionOpen);
  };

  //tax Akordiyon

  const [isBuyTaxOpen, setIsBuyTaxOpen] = useState(false);

  const toggleBuyTaxAccordion = () => {
    setIsBuyTaxOpen(!isBuyTaxOpen);
  };

  const [isSellTaxOpen, setIsSellTaxOpen] = useState(false);

  const toggleSellTaxAccordion = () => {
    setIsSellTaxOpen(!isSellTaxOpen);
  };

  //liquidity akordiyon

  const [isUniswapOpen, setIsUniswapOpen] = useState(false);

  const toggleIsUniswapAccordion = () => {
    setIsUniswapOpen(!isUniswapOpen);
  };

  const [isBurnOpen, setIsBurnOpen] = useState(false);

  const toggleIsBurnAccordion = () => {
    setIsBurnOpen(!isBurnOpen);
  };

  //sidebar sayfalar ileri geri

  const [step, setStep] = useState(0);

  const handleNext = () => {
    if (step < 4) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrev = () => {
    if (step > 0) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  //------------------------input yönetimi-----------------

  //token info sayfası
  const [data, setData] = useState({
    tokenname: "",
    tokensymbol: "",
    totalsupply: "",
    telegram: "",
    twitter: "",
    website: "",
    description: "",
    teamPercent: "",
    teamAddress: "",
    maxWallet: "",
    maxTx: "",
    buyTax: "",
    sellTax: "",
    taxWallet: "",
    liquidityEth: "",
    lockPeriod: "",
    lockMonth: "0",
  });

  const handleChange = (e) => {
    setData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    console.log(data);
  };

  const [tokensToLiq, setTokensToLiq] = useState("");
  useEffect(() => {
    const getTokensToLiq = () => {
      if (data.totalsupply && data.liquidityEth) {
        if (Number(data.teamPercent) > 0) {
          setTokensToLiq(
            (Number(data.totalsupply) / 100) * (100 - Number(data.teamPercent))
          );
        } else {
          setTokensToLiq(data.totalsupply);
        }
      } else {
        setTokensToLiq("");
      }
    };

    getTokensToLiq();
  }, [data]);

  const [ethPrice, setEthPrice] = useState(null);

  useEffect(() => {
    const fetchEthPrice = async () => {
      const res = await axios.get(
        "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
      );
      setEthPrice(res.data.USD);
    };
    fetchEthPrice();
  }, []);

  const [usdMCap, setUsdMCap] = useState("");

  useEffect(() => {
    if (ethPrice && data.liquidityEth) {
      if (data.teamPercent) {
        const currentCap = ethPrice * Number(data.liquidityEth);
        const hundredPercent = (currentCap / (100 - data.teamPercent)) * 100;
        setUsdMCap(hundredPercent.toFixed(4));
      } else {
        setUsdMCap(ethPrice * Number(data.liquidityEth).toFixed(4));
      }
    }
  }, [ethPrice, data]);

  return (
    <div className="wrapper">
      <div className="shape"></div>

      <section className="bloglist">
        <div className="container">
          <div className="row">
            <div className="bloglist__main">
              {/* sidebar */}
              <Sidebar setStep={setStep} />
              {/* Token info */}
              {step === 0 && (
                <div className="list details">
                  <form action="#" className="form-box">
                    <div className="row">
                      <div className="col">
                        <h5 className="mb-14">Leave your thought here</h5>
                        <p>
                          Your email address will not be published. Required
                          fields are marked *
                        </p>
                      </div>
                      <div className="line"></div>
                    </div>

                    <div className="row">
                      <Input
                        label={"Token Name "}
                        placeholder={"Name of your token "}
                        required={true}
                        name="tokenname"
                        onChange={handleChange}
                        value={data.tokenname}
                      />
                    </div>

                    <div className="row">
                      <Input
                        placeholder="Symbol of your token"
                        required={true}
                        label={
                          <>
                            Token Symbol
                            <span
                              style={{
                                marginLeft: "5px",
                                display: "inline-block",
                                borderRadius: "10px",
                              }}
                              title="Primary identification for your token across exchanges and trading platforms."
                            >
                              <FaQuestionCircle
                                style={{ color: "#007bff", cursor: "pointer" }}
                              />
                            </span>
                          </>
                        }
                        name="tokensymbol"
                        onChange={handleChange}
                        value={data.tokensymbol}
                      />
                      <Input
                        label={"Total Supply "}
                        placeholder={"Token Total Supply"}
                        required={true}
                        name="totalsupply"
                        onChange={handleChange}
                        value={data.totalsupply}
                      />
                    </div>
                    <InfoInput
                      link={"https://t.me/"}
                      label={"Telegram"}
                      name="telegram"
                      onChange={handleChange}
                      value={data.telegram}
                    />
                    <InfoInput
                      link={"https://x.com/"}
                      label={"Twitter"}
                      name="twitter"
                      onChange={handleChange}
                      value={data.twitter}
                    />
                    <InfoInput
                      link={"https://"}
                      label={"Website"}
                      name="website"
                      onChange={handleChange}
                      value={data.website}
                    />

                    <div className="row">
                      <div className="col mt-4">
                        <label>Short Description</label>
                        <textarea
                          cols="30"
                          rows="10"
                          onFocus={() => setFocusedTextArea(true)}
                          onBlur={() => setFocusedTextArea(false)}
                          style={{
                            background: "transparent",
                            borderRadius: "20px",
                            padding: "12px 15px",
                            fontWeight: "300",
                            fontSize: "14px",
                            fontFamily: '"Readex Pro"',
                            boxShadow: "none",
                            color: "#fff",
                            outline: "none",
                            border: focusedTextArea
                              ? "7px solid #5c27fe"
                              : "5px solid gray",
                            fontSize: "1.2rem",
                          }}
                          name="description"
                          onChange={handleChange}
                          value={data.description}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mb-0">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-end">
                          <button className="action-btn" onClick={handleNext}>
                            <span>Distribution</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {/* Distribution */}
              {step === 1 && (
                <div className="list details">
                  <form action="#" className="form-box">
                    <div className="row">
                      <div className="col">
                        <h5 className="mb-14">Distribution</h5>
                        <p>
                          Setup how your coin should be initially distributed.
                        </p>
                      </div>
                      <div className="line"></div>
                    </div>
                    <>
                      <div className="row">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              background: "transparent",
                              borderRadius: "20px",
                              color: "#fff",
                              outline: "none",
                              border: "4px solid #5c27fe",
                              width: "80%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "60px",
                            }}
                            className="mt-5"
                          >
                            <h5>Team Allocation</h5>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              style={{
                                width: "80px",
                                height: "50px",
                                marginTop: "5px",
                              }}
                              onChange={toggleAccordion}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={isOpen}
                            />
                          </div>
                        </div>
                      </div>

                      {isOpen && (
                        <>
                          <div className="row">
                            <label>Team supply allocation</label>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "space-between",
                              }}
                            >
                              <InfoInput
                                link={"%"}
                                label={""}
                                placeholder={""}
                                name="teamPercent"
                                value={data.teamPercent}
                                onChange={handleChange}
                              />
                              <div
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  background: "#ccc",
                                }}
                                className="mt-2"
                              >
                                <span style={{ alignSelf: "center" }}>=</span>
                              </div>
                              <InfoInput
                                link={""}
                                label={""}
                                value={
                                  data.totalsupply &&
                                  data.teamPercent &&
                                  (Number(data.totalsupply) / 100) *
                                    data.teamPercent
                                }
                                disabled={true}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <Input
                              label={"Team supply address "}
                              placeholder={"0x"}
                              required={true}
                              name="teamAddress"
                              value={data.teamAddress}
                              onChange={handleChange}
                            />
                          </div>
                          <p>
                            The address that will receive the team supply when
                            the token is launched.
                          </p>
                          <br />
                        </>
                      )}
                    </>

                    <div className="row mb-0">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <button className="action-btn" onClick={handlePrev}>
                            <span>Coin setup</span>
                          </button>
                          <button className="action-btn" onClick={handleNext}>
                            <span>Anti-bot</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <br />

              {/* Anti-bot */}
              {step === 2 && (
                <div className="list details">
                  <form action="#" className="form-box">
                    <div className="row">
                      <div className="col">
                        <h5 className="mb-14">Anti-bot protection</h5>
                        <p>
                          Optionally enable these features to protect your token
                          launch from bots and snipers. You can change these
                          settings until you renounce your contract.
                        </p>
                      </div>
                      <div className="line"></div>
                    </div>

                    <div className="row">
                      <p className="mt-3 mb-3">
                        Once you launch your coin, you can only increase or
                        disable your anti-bot limits.
                      </p>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            background: "transparent",
                            borderRadius: "20px",
                            color: "#fff",
                            outline: "none",
                            border: "4px solid #5c27fe",
                            width: "80%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "60px",
                          }}
                          className="mt-5"
                        >
                          <h5>Limit Per Wallet</h5>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            style={{
                              width: "80px",
                              height: "50px",
                              marginTop: "5px",
                            }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={isLimitPerWalletOpen}
                            onChange={toggleLimitPerWalletAccordion}
                          />
                        </div>
                      </div>
                    </div>

                    {isLimitPerWalletOpen && (
                      <>
                        <div className="row">
                          <label>Max tokens per wallet</label>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <InfoInput
                              link={"%"}
                              placeholder={"0"}
                              name="maxWallet"
                              value={data.maxWallet}
                              onChange={handleChange}
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#ccc",
                              }}
                              className="mt-2"
                            >
                              <span style={{ alignSelf: "center" }}>=</span>
                            </div>
                            <InfoInput
                              disabled={true}
                              value={
                                data.totalsupply &&
                                data.maxWallet &&
                                (Number(data.totalsupply) / 100) *
                                  Number(data.maxWallet)
                              }
                            />
                          </div>
                        </div>
                        <p>
                          The maximum share of the total supply a single wallet
                          address can hold
                        </p>
                        <br />
                      </>
                    )}

                    <div className="row">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            background: "transparent",
                            borderRadius: "20px",
                            color: "#fff",
                            outline: "none",
                            border: "4px solid #5c27fe",
                            width: "80%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "60px",
                          }}
                          className="mt-5"
                        >
                          <h5>Limit Per Wallet</h5>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            style={{
                              marginTop: "5px",
                              width: "80px",
                              height: "50px",
                            }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={isLimitPerTransactionOpen}
                            onChange={toggleLimitPerTransactionAccordion}
                          />
                        </div>
                      </div>
                    </div>

                    {isLimitPerTransactionOpen && (
                      <>
                        <div className="row">
                          <label>Max tokens per transaction</label>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <InfoInput
                              link={"%"}
                              placeholder={""}
                              name="maxTx"
                              value={data.maxTx}
                              onChange={handleChange}
                            />
                            <div
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#ccc",
                              }}
                              className="mt-2"
                            >
                              <span style={{ alignSelf: "center" }}>=</span>
                            </div>
                            <InfoInput
                              placeholder={""}
                              disabled={true}
                              value={
                                data.totalsupply &&
                                data.maxTx &&
                                (Number(data.totalsupply) / 100) *
                                  Number(data.maxTx)
                              }
                            />
                          </div>
                        </div>
                        <p>
                          The maximum number of tokens that can be traded in a
                          single trade
                        </p>{" "}
                        <br />
                      </>
                    )}

                    <div className="row mb-0">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <button className="action-btn" onClick={handlePrev}>
                            <span>Distribution</span>
                          </button>
                          <button className="action-btn" onClick={handleNext}>
                            <span>Taxes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <br />

              {/* taxes */}
              {step === 3 && (
                <div className="list details">
                  <form action="#" className="form-box">
                    <div className="row">
                      <div className="col">
                        <h5 className="mb-14">Coin trade taxes</h5>
                        <p>
                          Optionally add a buy and/or sell tax on Uniswap V2
                          trades. Taxes are auto-sold for ETH at regular
                          intervals.
                        </p>
                      </div>
                      <div className="line"></div>
                    </div>
                    <>
                      <div className="row">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              background: "transparent",
                              borderRadius: "20px",
                              color: "#fff",
                              outline: "none",
                              border: "4px solid #5c27fe",
                              width: "80%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "60px",
                            }}
                            className="mt-5"
                          >
                            <h5>Buy Tax</h5>
                          </div>

                          <div className="form-check form-switch">
                            <input
                              style={{
                                marginTop: "10px",
                                width: "80px",
                                height: "50px",
                              }}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={isBuyTaxOpen}
                              onChange={toggleBuyTaxAccordion}
                            />
                          </div>
                        </div>
                      </div>

                      {isBuyTaxOpen && (
                        <>
                          <div className="row">
                            <div style={{ marginTop: "3px" }}>
                              <InfoInput
                                link={"%"}
                                label={
                                  <>
                                    Buy Tax
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                        display: "inline-block",
                                        borderRadius: "10px",
                                      }}
                                      title="Tax token on token buys."
                                    >
                                      <FaQuestionCircle
                                        style={{
                                          color: "#007bff",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </span>
                                  </>
                                }
                                placeholder={"0"}
                                name="buyTax"
                                value={data.buyTax}
                                onChange={handleChange}
                              />
                            </div>
                            {/* Açıklama Label */}
                            <div className="mt-2">
                              <label
                                style={{
                                  background: "transparent",
                                  borderRadius: "20px",
                                  padding: "12px 15px",
                                  fontWeight: "300",
                                  fontSize: "14px",
                                  fontFamily: '"Readex Pro"',
                                  boxShadow: "none",
                                  color: "#fff",
                                  outline: "none",
                                  border: focusedTextArea
                                    ? "7px solid #5c27fe"
                                    : "5px solid gray",
                                  fontSize: "1.2rem",
                                }}
                              >
                                No buy tax
                                <br />
                                Metadrop adds a small tax on top of your buy tax
                                as a fee.
                                <br />
                                Our tax is removed 30 days after your coin
                                launches, or when your tax is set to 0%.
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </>

                    <div className="row">
                      <br />
                      <br />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            background: "transparent",
                            borderRadius: "20px",
                            color: "#fff",
                            outline: "none",
                            border: "4px solid #5c27fe",
                            width: "80%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "60px",
                          }}
                          className="mt-5"
                        >
                          <h5>Sell Tax</h5>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            style={{
                              marginTop: "10px",
                              width: "80px",
                              height: "50px",
                            }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked={isSellTaxOpen}
                            onChange={toggleSellTaxAccordion}
                          />
                        </div>
                      </div>
                    </div>

                    {isSellTaxOpen && (
                      <>
                        <div className="row">
                          <div style={{ gap: "10px", marginTop: "3px" }}>
                            <InfoInput
                              link={"%"}
                              label={
                                <>
                                  Sell Tax
                                  <span
                                    style={{
                                      marginLeft: "5px",
                                      display: "inline-block",
                                      borderRadius: "10px",
                                    }}
                                    title="Tax token on token sells."
                                  >
                                    <FaQuestionCircle
                                      style={{
                                        color: "#007bff",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </span>
                                </>
                              }
                              placeholder={"0"}
                              name="sellTax"
                              value={data.sellTax}
                              onChange={handleChange}
                            />
                          </div>
                          {/* Açıklama Label */}
                          <div className="mt-2">
                            <label
                              style={{
                                background: "transparent",
                                borderRadius: "20px",
                                padding: "12px 15px",
                                fontWeight: "300",
                                fontSize: "14px",
                                fontFamily: '"Readex Pro"',
                                boxShadow: "none",
                                color: "#fff",
                                outline: "none",
                                border: focusedTextArea
                                  ? "7px solid #5c27fe"
                                  : "5px solid gray",
                                fontSize: "1.2rem",
                              }}
                            >
                              No sell tax
                              <br />
                              Metadrop adds a small tax on top of your sell tax
                              as a fee.
                              <br />
                              Our tax is removed 30 days after your coin
                              launches, or when your tax is set to 0%.
                            </label>
                          </div>
                        </div>
                      </>
                    )}

                    {(isSellTaxOpen || isBuyTaxOpen) && (
                      <>
                        <div className="row">
                          <Input
                            label={"Tax payout address "}
                            placeholder={"0x"}
                            required={true}
                            name="taxWallet"
                            value={data.taxWallet}
                            onChange={handleChange}
                          />
                        </div>
                        <p>
                          This is the address to which earned taxes will be paid
                          out.
                        </p>
                        <br />
                      </>
                    )}

                    <div className="row mb-0">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <button className="action-btn" onClick={handlePrev}>
                            <span>Anti-bot</span>
                          </button>
                          <button className="action-btn" onClick={handleNext}>
                            <span>Liquidity</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <br />

              {/* liquidity */}
              {step === 4 && (
                <div className="list details">
                  <form action="#" className="form-box">
                    <div className="row">
                      <div className="col">
                        <h5 className="mb-14">Initial liquidity</h5>
                        <p>
                          Pair the remainder of your token supply with an amount
                          of ETH you choose to set up the initial liquidity
                          pool. You can do this after your token is launched,
                          but we recommend doing it when you launch your token.
                        </p>
                      </div>
                      <div className="line"></div>
                    </div>
                    <>
                      <div className="row">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              background: "transparent",
                              borderRadius: "20px",
                              color: "#fff",
                              outline: "none",
                              border: "4px solid #5c27fe",
                              width: "80%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "60px",
                              padding: "2px",
                            }}
                            className="mt-5"
                          >
                            <h5>Fund the Uniswap pool on launch</h5>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              style={{
                                marginTop: "10px",
                                width: "80px",
                                height: "50px",
                              }}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={isUniswapOpen}
                              onChange={toggleIsUniswapAccordion}
                            />
                          </div>
                        </div>
                      </div>

                      {isUniswapOpen && (
                        <>
                          <div className="row">
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "space-around",
                              }}
                            >
                              <label>Deposit ETH</label>
                              <label>Deposit Tokens</label>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "space-between",
                              }}
                            >
                              <Input
                                placeholder={"0"}
                                name="liquidityEth"
                                value={data.liquidityEth}
                                onChange={handleChange}
                              />
                              <Input disabled={true} value={tokensToLiq} />
                            </div>
                          </div>

                          <div className="row">
                            <InfoInput
                              label={"Balance: \u039e 0,00"}
                              link={"Marketcap at launch \u0024"}
                              disabled={true}
                              value={usdMCap}
                            />
                          </div>
                          <br />
                        </>
                      )}

                      <div className="row">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              background: "transparent",
                              borderRadius: "20px",
                              color: "#fff",
                              outline: "none",
                              border: "4px solid #5c27fe",
                              width: "80%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "60px",
                              padding: "2px",
                            }}
                            className="mt-5"
                          >
                            <h5>Lock or Burn Liquidity</h5>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              style={{
                                marginTop: "10px",
                                width: "80px",
                                height: "50px",
                              }}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              checked={isBurnOpen}
                              onChange={toggleIsBurnAccordion}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <p>
                          LP tokens will be burnt when funding, and initial
                          liquidity will be locked forever.
                        </p>
                      </div>
                      <br />

                      {isBurnOpen && (
                        <>
                          <div className="row">
                            <label>LP token lock period</label>
                            <div
                              style={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "space-between",
                              }}
                            >
                              <InfoInput
                                label={""}
                                placeholder={"0"}
                                name="lockPeriod"
                                value={data.lockPeriod}
                                onChange={handleChange}
                              />
                              <select
                                aria-label="Default select example"
                                style={{
                                  flex: 1,
                                  background: "transparent",
                                  borderRadius: "20px",
                                  padding: "12px 15px",
                                  fontWeight: "300",
                                  fontSize: "14px",
                                  fontFamily: '"Readex Pro"',
                                  boxShadow: "none",
                                  color: "#fff",
                                  outline: "none",
                                  border: focusedTextArea
                                    ? "7px solid #5c27fe"
                                    : "5px solid gray",
                                  fontSize: "1.2rem",
                                }}
                                onChange={handleChange}
                                name="lockMonth"
                                value={data.lockMonth}
                              >
                                <option value="0">Years</option>
                                <option value="1">Months</option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <p>
                              The amount of time you're willing to lock the
                              initial liquidity you're adding for your token.
                              After the initial lock period expires, you are
                              free to withdraw the LP tokens or lock them again.
                            </p>
                          </div>
                          <br />
                        </>
                      )}
                    </>
                    <div className="row mb-0">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <button className="action-btn" onClick={handlePrev}>
                            <span>Taxes</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <br />
    </div>
  );
}

export default BlogDetails;
